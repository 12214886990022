import { Link, Button, makeStyles } from '@fluentui/react-components';
import { TableRegular, ChartMultipleRegular } from '@fluentui/react-icons';
import React, { AnchorHTMLAttributes, FC, useCallback, useMemo, useEffect, useRef } from 'react';
import { Attachment, IChatMessage } from '../../libs/models/ChatMessage';
import { useAttachment } from '../../contexts/AttachmentContext';

interface CustomLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href?: string;
    children?: React.ReactNode;
    message: IChatMessage;
    isLastMessage: boolean;
}

const useStyles = makeStyles({
    icon: {
        backgroundColor: 'var(--green-50)',
        color: 'white',
        padding: '6px',
        marginLeft: '-4px',
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const getAttachment = (children: React.ReactNode, message: IChatMessage): Attachment | null => {
    if (typeof children === 'string' && children.startsWith('attachment')) {
        const attachmentIndex = parseInt(children.split(':')[1], 10);
        return message.attachments?.[attachmentIndex] ?? null;
    }
    return null;
};

const CustomLink: FC<CustomLinkProps> = React.memo(
    ({ href, children, message, isLastMessage, ...props }) => {
        const { setActiveAttachment } = useAttachment();
        const attachment = useMemo(() => getAttachment(children, message), [children, message]);
        const hasBeenShown = useRef(false);
        const classes = useStyles();

        const handleClick = useCallback(() => {
            hasBeenShown.current = true;
            setActiveAttachment(attachment, message);
        }, [attachment, message, setActiveAttachment]);

        useEffect(() => {
            if (isLastMessage && attachment && !hasBeenShown.current) {
                setActiveAttachment(attachment, message);
                hasBeenShown.current = true;
            }
        }, [attachment, isLastMessage, message, setActiveAttachment]);

        const renderButtonWithIcon = useCallback(
            (IconComponent: React.ElementType) => (
                <Button
                    onClick={handleClick}
                    {...props}
                    as="a"
                    icon={
                        <span className={classes.icon}>
                            <IconComponent />
                        </span>
                    }
                >
                    {attachment?.name}
                </Button>
            ),
            [handleClick, props, classes.icon, attachment],
        );

        const renderAttachment = useCallback(() => {
            if (!attachment) {
                return (
                    <Link href={href} target="_blank" rel="noopener noreferrer" {...props}>
                        {children}
                    </Link>
                );
            }

            switch (attachment.attachmentType) {
                case 'table':
                    return renderButtonWithIcon(TableRegular);
                case 'chart':
                    return renderButtonWithIcon(ChartMultipleRegular);
                default:
                    return (
                        <Link href={href} {...props}>
                            {children}
                        </Link>
                    );
            }
        }, [attachment, href, props, children, renderButtonWithIcon]);

        return renderAttachment();
    },
    (prevProps, nextProps) => {
        return (
            prevProps.href === nextProps.href &&
            prevProps.children === nextProps.children &&
            prevProps.message === nextProps.message &&
            prevProps.isLastMessage === nextProps.isLastMessage &&
            JSON.stringify(prevProps.message.attachments) === JSON.stringify(nextProps.message.attachments)
        );
    },
);

CustomLink.displayName = 'CustomLink';

export default CustomLink;
